export const pagesNav = [
    {
        name: 'job_offer',
        path: '/talent/search/job_offers',
        icon: 'fa-solid fa-magnifying-glass',
        direct_link: false,
        options: [
            'Modifiez votre CV',
            'Gérez les offres emploi',
            'Suivez les candidatures',
        ],
        subs: [
            {
                name: 'my.cv',
                path: '/talent/preview',
                icon: 'fa-solid fa-file',
                options: [
                    'Modifiez votre parcours',
                    'Ajustez vos compétences',
                    'Changez votre rémunération',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'job_offer',
                path: '/talent/search/job_offers',
                icon: 'fa-solid fa-magnifying-glass',
                options: [
                    'Filtrez les offres',
                    'Comparez les offres',
                    'Postulez aux offres',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'candidatures',
                path: '/talent/candidature',
                icon: 'fa-solid fa-wand-magic-sparkles',
                options: [
                    'Suivez vos candidatures',
                    'Passez des entretiens',
                    'Signez votre contrat',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'favorite',
                path: '/talent/search/job_offers/favorites',
                icon: 'fa-solid fa-user-check',
                options: [
                    'Constituez vos favoris',
                    'Comparez vos favoris',
                    'Gérez vos favoris',
                ],
                button_name: "Consulter",
                break: false
            },
        ]
    },
    {
        name: 'portage',
        path: '/talent/portage',
        icon: 'fa-solid fa-user-shield',
        direct_link: true,
        options: [
            'J\'ai déjà trouvé un travail',
            'Je veux un contrat de portage',
            'J\'invite l\'entreprise à rejoindre Talenteum',
        ],
        subs: [
            {
                name: 'port_salary',
                path: '/talent/port_salary',
                icon: 'fa-solid fa-address-card',
                options: [
                    'Précisez votre rémunération souhaitée',
                    'Communiquez-nous les coordonnées de l\'entreprise',
                    'Signez votre contrat de portage salarial',
                    'Débutez votre collaboration à la date convenue avec l\'entreprise',
                ],
                button_name: "Demander",
                break: false
            },
            {
                name: 'port_independant',
                path: '/talent/port_independant',
                icon: 'fa-solid fa-user-tag',
                options: [
                    'Précisez votre rémunération souhaitée',
                    'Communiquez-nous les coordonnées de l\'entreprise',
                    'Signez votre contrat de travailleur indépendant',
                    'Débutez votre collaboration à la date convenue avec l\'entreprise',
                ],
                button_name: "Demander",
                break: false
            }
        ]
    },
    {
        name: 'parainage',
        path: '/talent/parainage',
        icon: 'fa-solid fa-hand-holding-hand',
        direct_link: true,
        options: [
            'Parrainez un talent',
            'Parrainez une entreprise',
            'Gérez votre communauté',
        ],
        subs: [
            {
                name: 'refer_talent',
                path: '/talent/refer_talent',
                icon: 'fa-solid fa-people-carry-box',
                options: [
                    'Invitez un talent à devenir membre',
                    'Contribuez au rayonnement de la communauté Talenteum',
                    'Percevez des compléments de revenu',
                ],
                button_name: "Parrainer",
                break: false
            },
            {
                name: 'refer_entreprise',
                path: '/talent/refer_entreprise',
                icon: 'fa-solid fa-hand-holding-dollar',
                options: [
                    'Invitez une entreprise à devenir membre',
                    'Donnez-lui accès à un vivier de talents',
                    'Percevez des compléments de revenu',
                ],
                button_name: "Parrainer",
                break: false
            },
            /*{
                name: 'my_referrals',
                path: '/company/my_referrals',
                icon: 'fa-solid fa-people-arrows',
                options: [
                    'Consultez mes filleuls',
                    'Suivez les sous-filleuls',
                    'Gérez les primes de parrainage',
                ],
                button_name: "Consulter",
                break: false
            }*/
        ]
    }
]

export const pagesAccNav = [
    {
        name: 'account',
        path: '/account',
        icon: 'fa-solid fa-circle-user',
        options: [
            'Notez vos préférences',
            'Modifiez vos données personnelles',
            'Changez votre mot de passe',
        ],
        subs: [
            {
                name: 'preferences',
                path: '/talent/preference',
                icon: 'fa-solid fa-asterisk',
                options: [
                    'Changez votre devise, fuseau horaire et langue',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'avatar',
                path: '/talent/avatar',
                icon: 'fa-solid fa-id-badge',
                options: [
                    'Changez votre avatar',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'coordonnes',
                path: '/talent/personal-data',
                icon: 'fa-solid fa-address-book',
                options: [
                    'Mettre à jour vos données perso',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'my.documents',
                path: '/talent/documents',
                icon: 'fa-solid fa-id-card-clip',
                options: [
                    'Téléchargez vos documents',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'my.contract',
                path: '/talent/my_contract',
                icon: 'fa-solid fa-file-contract',
                options: [
                    'Consulter votre timesheet',
                ],
                button_name: "Consulter",
                break: false
            },
            /*{
                name: 'timesheet',
                path: '/talent/timesheet',
                icon: 'fa-solid fa-file',
                options: [
                    'Mettre à jour votre timesheet',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'expense.report',
                path: '/talent/expense_report',
                icon: 'fa-solid fa-file',
                options: [
                    'Mettre à jour vos notes de frais',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'holidays',
                path: '/talent/holiday',
                icon: 'fa-solid fa-calendar',
                options: [
                    'Demander des congés',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'bonus',
                path: '/talent/bonus',
                icon: 'fa-solid fa-award',
                options: [
                    'Accepter des primes',
                ],
                button_name: "Consulter",
                break: false
            },*/
            {
                name: 'remuneration',
                path: '/talent/renumeration',
                icon: 'fa-solid fa-euro-sign',
                options: [
                    'Téléchargez vos fiche de paye',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'mdp',
                path: '/talent/mdp',
                icon: 'fa-solid fa-lock',
                options: [
                    'Changez votre mot de passe'
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'disconnect',
                path: '/disconnect',
                icon: 'fa-solid fa-right-from-bracket',
                options: [
                    'Déconnectez-vous',
                ],
                button_name: "Déconnexion",
                break: false
            }
        ]
    },
]

export const pageLayoutMaxWidth = '1000px'